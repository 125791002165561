












import { Component, Vue } from 'vue-property-decorator'
import ContentArea from '@/components/common/ContentArea.vue'
import Outcome from '@/components/Outcome.vue'
import { LocaleMessages } from 'vue-i18n'

interface IText {
  title: LocaleMessages | string
  description: LocaleMessages | string
}
interface IHighlights {
  descriptionTextKey: string
  iconSrc: string
  status: number
  titleTextKey: string
}

@Component({
  components: {
    ContentArea,
    Outcome
  }
})

export default class Positive extends Vue {
  private outcomeContent: IText = {
    title: '',
    description: ''
  }

  private created (): void {
    const isHighlights = JSON.parse(sessionStorage.getItem('highlights') || '')
    const fullyVaccinated = isHighlights.find((el: IHighlights) => el.titleTextKey.includes('fully_vaccinated'))
    const notExposed = isHighlights.find((el: IHighlights) => el.titleTextKey.includes('not_exposed'))
    if (fullyVaccinated && fullyVaccinated.status && notExposed && notExposed.status) {
      this.outcomeContent.title = this.$t('outcome.positive[1].title')
      this.outcomeContent.description = this.$t('outcome.positive[1].description')
    } else {
      this.outcomeContent.title = this.$t('outcome.positive[0].title')
      this.outcomeContent.description = this.$t('outcome.positive[0].description')
    }
  }
}
